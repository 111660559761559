import { get, post, rawPost } from "@/services/api";
import { getLocalStorage } from "@/services/utils";
import { getSessionsByPractitionerRole } from "../Appointments/appointmentsModule";
import { getAudioDuration, getProviderSessionIds } from "../SessionDetails/sessionDetailsModule";
import moment from "moment-timezone";
import { getOldSessionTranscriptData } from "@/store/modules/userModule";
import { toast } from "react-toastify";
import { marked } from "marked";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

export const getSessionsByPatientId = patientId => {
  return async (dispatch, getState) => {
    let state = getState();
    let orgId = state.user.selectedUserRole?.organizationId;
    let data = [];
    dispatch({ type: "TOGGLE_PATIENT_SESSIONS_LOADER", show: true });
    try {
      let url = `practitioner-role/individual-session/find-all-by-patient/${patientId}`;
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = response.data;
      }
    } catch (error) {}
    try {
      let url = `practitioner-role/sessions/find-all-by-patient/${patientId}`;
      let response = await get(url);
      if (response?.data?.length > 0) {
        // let groupData = response.data.filter(groupSession => {
        //   let isPatientInSession = !!groupSession.sessionPatientMemberDtos?.find?.(
        //     i => i.patientId === patientId
        //   );
        //   return isPatientInSession;
        // });
        let groupData = response.data;
        data = [
          ...data,
          ...groupData.map(i => {
            return { ...i, sessionCategory: "GROUP" };
          }),
        ];
      }
    } catch (error) {}
    data = data.sort(
      (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
    );
    dispatch({ type: "ADD_PATIENT_SESSIONS_DATA", data });
    dispatch({ type: "TOGGLE_PATIENT_SESSIONS_LOADER", show: false });
    return data;
  };
};

export const getLatestPatientSessionMemberNotes = patientId => {
  return async dispatch => {
    let patientSessionMemberNotes = [];
    let latestSoapNote = null;
    let soapNotesJson = null;
    let latestBhPredictions = null;
    let url = `practitioner-role/session-member-notes/patient/${patientId}/find`;
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING", show: true });
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        patientSessionMemberNotes = response.data;
      }
      patientSessionMemberNotes = patientSessionMemberNotes.sort(
        (notes1, notes2) => new Date(notes2.createdAt) - new Date(notes1.createdAt)
      );
      for (let i = 0; i < patientSessionMemberNotes.length; i++) {
        let patientNotes = patientSessionMemberNotes[i];
        if (patientNotes?.soapNote && !latestSoapNote) {
          latestSoapNote = patientNotes.soapNote;
        }
        if (patientNotes?.behaviouralHealthPredictions && !latestBhPredictions) {
          latestBhPredictions = patientNotes.behaviouralHealthPredictions;
        }
        if (
          patientNotes?.jsonSoapNote &&
          (patientNotes.jsonSoapNote.subjective || patientNotes.jsonSoapNote.Subjective) &&
          (patientNotes.jsonSoapNote.assessment || patientNotes.jsonSoapNote.Assessment) &&
          (patientNotes.jsonSoapNote.objective || patientNotes.jsonSoapNote.Objective) &&
          !soapNotesJson
        ) {
          soapNotesJson = {
            ...patientNotes.jsonSoapNote,
            subjective:
              patientNotes.jsonSoapNote.subjective || patientNotes.jsonSoapNote.Subjective,
            assessment:
              patientNotes.jsonSoapNote.assessment || patientNotes.jsonSoapNote.Assessment,
            objective: patientNotes.jsonSoapNote.objective || patientNotes.jsonSoapNote.Objective,
          };
        } else if (
          patientNotes?.jsonSoapNote &&
          patientNotes.jsonSoapNote.narrative &&
          !soapNotesJson
        ) {
          soapNotesJson = patientNotes.jsonSoapNote;
        }
        if (soapNotesJson && latestBhPredictions) {
          break;
        }
      }
    } catch (error) {}
    dispatch({
      type: "ADD_PATIENT_DETAILS_SOAP_NOTES_DATA",
      data: latestSoapNote,
      soapNotesJson,
    });
    dispatch({
      type: "ADD_PATIENT_DETAILS_BH_PREDICTIONS_DATA",
      data: latestBhPredictions,
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING", show: false });
  };
};

const getPatientCompletedSessions = patientId => {
  return async (dispatch, getState) => {
    let state = getState();
    let allSessions = state.appointments.sessions?.data || [];
    if (allSessions.length === 0) {
      allSessions = await dispatch(getSessionsByPractitionerRole());
    }
    let completedSessions = allSessions.filter(
      session =>
        // !!session.endTime &&
        // new Date(session.endTime) < new Date() &&
        session.sessionCategory === "INDIVIDUAL"
    );
    completedSessions = completedSessions.sort(
      (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
    );
    completedSessions = completedSessions.filter(i => i.patientId === patientId);
    completedSessions = completedSessions.filter(
      i => !i.appointmentType || i.appointmentType !== "INTAKE"
    );
    return completedSessions;
  };
};

// /session-member-notes/patient/{patientId}/find
export const getSessionSoapNotes = async options => {
  let sessionSoapNotes = [];
  let url = `practitioner-role/session-member-notes/session/${options?.sessionId}/find`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      sessionSoapNotes = response.data;
    }
  } catch (error) {}
  return sessionSoapNotes;
};

export const getLatestSoapNotes = options => {
  return async dispatch => {
    let patientId = options?.patientId;
    let latestSoapNote = null;
    let soapNotesJson = null;
    let latestBhPredictions = null;
    let soapNotesSession = null;
    let bhPredictionsSession = null;
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING", show: true });
    let previousSessions = await dispatch(getPatientCompletedSessions(patientId));
    for (let i = 0; i < previousSessions.length; i++) {
      let sessionId = previousSessions[i].id;
      let sessionSoapNotes = await getSessionSoapNotes({ ...options, sessionId });
      let userSoapNote = sessionSoapNotes.find(i => i.patientId === patientId);
      if (userSoapNote?.soapNote && !latestSoapNote) {
        latestSoapNote = userSoapNote.soapNote;
        soapNotesSession = previousSessions[i];
      }
      if (userSoapNote?.behaviouralHealthPredictions && !latestBhPredictions) {
        latestBhPredictions = userSoapNote.behaviouralHealthPredictions;
        bhPredictionsSession = previousSessions[i];
      }
      if (userSoapNote?.jsonSoapNote && !soapNotesJson) {
        soapNotesJson = userSoapNote.jsonSoapNote;
        soapNotesSession = previousSessions[i];
      }
      if (soapNotesJson && latestBhPredictions) {
        // soapNotesSession = previousSessions[i];
        // bhPredictionsSession = previousSessions[i];
        // console.log("session found", sessionId, patientId);
        break;
      }
      // console.log("not found", sessionId);
    }
    dispatch({
      type: "ADD_PATIENT_DETAILS_SOAP_NOTES_DATA",
      data: latestSoapNote,
      session: soapNotesSession,
      soapNotesJson,
    });
    dispatch({
      type: "ADD_PATIENT_DETAILS_BH_PREDICTIONS_DATA",
      data: latestBhPredictions,
      session: bhPredictionsSession,
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING", show: false });
  };
};

export const getPatientPrivateNotes = options => {
  return async dispatch => {
    let userId = options?.patientId;
    let groupId = options?.groupId;
    let url = `member-notes/${groupId}/find`;
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_PRIVATE_NOTES_LOADING", show: true });
    let userNotes = "";
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        let data = response.data.find(i => i.userId === userId);
        userNotes = data?.cumulativeNotes || "";
      }
    } catch (error) {}
    dispatch({ type: "ADD_PATIENT_DETAILS_PRIVATE_NOTES_DATA", data: userNotes });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_PRIVATE_NOTES_LOADING", show: false });
  };
};

export const getDischargeSummary = options => {
  return async dispatch => {
    let patientId = options.patientId;
    let url = `practitioner-role/discharge-summary/mvp/find-all-by-patient/${patientId}`;
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", patientId, show: true });
    let dischargeSummaryData = null;
    let selectedIntakeSession = null;
    let selectedLatestSession = null;
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        let data = response.data.sort(
          (data1, data2) => new Date(data2.updatedAt) - new Date(data1.updatedAt)
        );
        dischargeSummaryData = data[0];
        selectedIntakeSession = dischargeSummaryData?.dischargeSummary?.intakeSession || null;
        selectedLatestSession = dischargeSummaryData?.dischargeSummary?.latestSession || null;
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA",
      patientId,
      data: dischargeSummaryData,
    });
    dispatch({
      type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_SESSION_DATA",
      patientId,
      data: {
        intakeSession: selectedIntakeSession,
        latestSession: selectedLatestSession,
      },
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", patientId, show: false });
    return dischargeSummaryData;
  };
};

export const generateDischargeSummary = options => {
  return async dispatch => {
    let intakeSessions = options?.intakeSessions;
    let sessions = options?.sessions;
    let patientId = options?.patientId;
    let dischargeSummaryData = null;
    let extraData = null;
    let selectedIntakeSession = null;
    let selectedLatestSession = null;
    let intakeNote = "";
    let lastSessionTranscript = "";
    dispatch({
      type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_SESSION_DATA",
      patientId,
      data: {
        intakeSession: null,
        latestSession: null,
      },
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", patientId, show: true });
    for (let i = 0; i < intakeSessions.length; i++) {
      let intakeSession = intakeSessions[i];
      let sessionSoapNotes = await getSessionSoapNotes({ sessionId: intakeSession.id });
      let soapNotesJson =
        sessionSoapNotes.find(i => i.patientId === patientId)?.jsonSoapNote || null;
      let hpiNote = soapNotesJson?.["intake-assessment"]?.intakeHPINote || "";
      if (hpiNote && !intakeNote) {
        intakeNote = hpiNote;
        selectedIntakeSession = intakeSession;
        break;
      }
    }
    for (let i = 0; i < sessions.length; i++) {
      let session = sessions[i];
      let transcriptData = await dispatch(
        getProviderSessionIds({
          sessionCategory: session?.sessionCategory === "GROUP" ? "group" : "individual",
          sessionId: session.id,
          triggerTime: new Date(),
          fromTasks: true,
        })
      );
      let transcriptText = "";
      if (transcriptData?.length > 0) {
        transcriptData.forEach(transcript => {
          let memberName = transcript.memberName;
          transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
        });
      }
      if (transcriptText && !lastSessionTranscript) {
        lastSessionTranscript = transcriptText;
        selectedLatestSession = session;
        break;
      }
    }
    dispatch({
      type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_SESSION_DATA",
      patientId,
      data: {
        intakeSession: selectedIntakeSession,
        latestSession: selectedLatestSession,
      },
    });
    if (!intakeNote || !lastSessionTranscript) {
      if (!intakeNote) {
        toast.error("No Intake Session Found");
      }
      if (!lastSessionTranscript) {
        toast.error("No Follow-up Session Found");
      }
    } else {
      let dischargeSummaryPayload = { intakeNote, lastSessionTranscript };
      let url = `https://transcript.${
        APP_ENV === "DEV" ? "dev" : "prod"
      }.soulsidehealth.com/api/v1/llm/transcript/generate-discharge-summary-from-intake-note-and-last-session-transcript`;
      try {
        let response = await rawPost(url, dischargeSummaryPayload);
        if (response?.data) {
          dischargeSummaryData = response.data;
          dischargeSummaryData = {
            ...dischargeSummaryData,
            dischargeSummary: {
              data: dischargeSummaryData.dischargeSummary
                ? marked.parse(dischargeSummaryData.dischargeSummary)
                : "",
              intakeSession: selectedIntakeSession,
              latestSession: selectedLatestSession,
            },
          };
          extraData = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
    dispatch({
      type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA",
      patientId,
      data: dischargeSummaryData,
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", patientId, show: false });
    return { dischargeSummaryData, extraData, selectedIntakeSession, selectedLatestSession };
  };
};

export const saveDischargeSummary = options => {
  return async dispatch => {
    let patientId = options.patientId;
    let url = `practitioner-role/discharge-summary/mvp/create-new`;
    if (!!options?.isEdit) {
      url = `practitioner-role/discharge-summary/mvp/update`;
    }
    let payload = options?.payload || {};
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", patientId, show: true });
    let dischargeSummaryData = null;
    try {
      let response = await post(url, payload);
      if (response?.data) {
        dischargeSummaryData = response.data;
        toast.success("Discharge Summary Saved");
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA",
      patientId,
      data: dischargeSummaryData,
    });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", patientId, show: false });
  };
};

export const generateDischargeSummaryOld = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let preferredTimezone = state.user.preferredTimezone;
    let sessions = options?.sessions;
    let dischargeSummaryPayload = { transcriptAndOtherInfoDtos: [] };
    let dischargeSummaryData = null;
    let promises = [];
    for (let i = 0; i < sessions.length; i++) {
      let session = sessions[i];
      let sessionDate = new Date(session.startTime);
      let updateDate = new Date("2024-09-09T06:00:00.000+05:30");
      let inPersonOldSession = sessionDate < updateDate && session.modeOfDelivery === "IN_PERSON";
      let promise = inPersonOldSession
        ? getOldSessionTranscriptData(session)
        : dispatch(
            getProviderSessionIds({
              sessionCategory: session?.sessionCategory === "GROUP" ? "group" : "individual",
              sessionId: session.id,
              triggerTime: new Date(),
              fromTasks: true,
            })
          );
      promises.push(promise);
    }
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", show: true });
    let responses = await Promise.allSettled(promises);
    if (responses?.length > 0) {
      responses.forEach((response, index) => {
        let transcriptData = response?.value;
        if (transcriptData?.length > 0) {
          let sessionDate = sessions?.[index]?.startTime
            ? moment(sessions[index].startTime)
                .tz(preferredTimezone?.name || "America/Los_Angeles")
                .format("YYYY-MM-DD")
            : "";
          if (sessionDate) {
            let transcriptText = "";
            transcriptData.forEach(transcript => {
              let memberName = transcript.memberName;
              transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
            });
            if (dischargeSummaryPayload.transcriptAndOtherInfoDtos.length < 4) {
              dischargeSummaryPayload.transcriptAndOtherInfoDtos = [
                ...dischargeSummaryPayload.transcriptAndOtherInfoDtos,
                {
                  date: sessionDate,
                  // weekNumber: dischargeSummaryPayload.transcriptAndOtherInfoDtos.length + 1,
                  transcript: transcriptText,
                },
              ];
            }
          }
        }
      });
    }
    if (dischargeSummaryPayload.transcriptAndOtherInfoDtos.length > 0) {
      dischargeSummaryPayload.transcriptAndOtherInfoDtos =
        dischargeSummaryPayload.transcriptAndOtherInfoDtos.reverse();
      dischargeSummaryPayload.transcriptAndOtherInfoDtos =
        dischargeSummaryPayload.transcriptAndOtherInfoDtos.map((i, index) => {
          return { ...i, weekNumber: index + 1 };
        });
      let url = `https://transcript.${
        APP_ENV === "DEV" ? "dev" : "prod"
      }.soulsidehealth.com/api/v1/llm/transcript/generate-discharge-summary-from-transcripts`;
      try {
        let response = await rawPost(url, dischargeSummaryPayload);
        if (response?.data) {
          dischargeSummaryData = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
    dispatch({ type: "ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA", data: dischargeSummaryData });
    dispatch({ type: "TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING", show: false });
    return dischargeSummaryData;
  };
};

export const getPatientVoiceEnrollmentData = options => {
  return async dispatch => {
    let patientId = options.patientId;
    let url = `practitioner-role/patient/speaker-profile-enrollment/find-by-id/${patientId}`;
    try {
      let response = await get(url);
      let speakerProfileEnrollmentFileUrl = response?.data || null;
      if (speakerProfileEnrollmentFileUrl) {
        try {
          let speakerProfileEnrollmentFileContent = null;
          try {
            speakerProfileEnrollmentFileContent = await fetch(speakerProfileEnrollmentFileUrl, {
              headers: {
                Range: "bytes=0-1000",
              },
            });
          } catch (error) {
            console.log("Error fetching speaker profile enrollment:", error);
            return;
          }
          let speakerProfileEnrollmentStatus = null;
          if (speakerProfileEnrollmentFileContent && speakerProfileEnrollmentFileContent.ok) {
            const audioDuration = await getAudioDuration(speakerProfileEnrollmentFileContent);
            speakerProfileEnrollmentStatus = {
              speakerType: "Patient",
              patientId,
              speakerProfileEnrollmentFileUrl,
              speakerProfileEnrolled: true,
              audioDuration,
            };
            dispatch({
              type: "ADD_PATIENT_DETAILS_VOICE_ENROLLMENT_DATA",
              data: speakerProfileEnrollmentStatus,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetPatientDetails = () => {
  return dispatch => {
    dispatch({ type: "RESET_PATIENT_DETAILS" });
  };
};

const initState = {
  soapNotesLoading: false,
  soapNotes: null,
  soapNotesJson: null,
  soapNotesSession: null,
  behaviouralHealthPredictions: null,
  behaviouralHealthPredictionsSession: null,
  privateNotesLoading: false,
  privateNotes: null,
  dischargeSummary: {},
  dischargeSummarySession: {},
  patientSessions: {
    data: [],
    loading: false,
  },
  voiceEnrollmentData: null,
};

const ACTION_HANDLERS = {
  ["TOGGLE_PATIENT_DETAILS_SOAP_NOTES_LOADING"]: (state, action) => {
    return {
      ...state,
      soapNotesLoading: action.show,
    };
  },
  ["ADD_PATIENT_DETAILS_SOAP_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      soapNotes: action.data,
      soapNotesSession: action.session,
      soapNotesJson: action.soapNotesJson,
    };
  },
  ["ADD_PATIENT_DETAILS_BH_PREDICTIONS_DATA"]: (state, action) => {
    return {
      ...state,
      behaviouralHealthPredictions: action.data,
      behaviouralHealthPredictionsSession: action.session,
    };
  },
  ["TOGGLE_PATIENT_DETAILS_PRIVATE_NOTES_LOADING"]: (state, action) => {
    return {
      ...state,
      privateNotesLoading: action.show,
    };
  },
  ["ADD_PATIENT_DETAILS_PRIVATE_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      privateNotes: action.data,
    };
  },
  ["TOGGLE_PATIENT_DETAILS_DISCHARGE_SUMMARY_LOADING"]: (state, action) => {
    return {
      ...state,
      dischargeSummary: {
        ...state.dischargeSummary,
        [action.patientId]: state.dischargeSummary[action.patientId]
          ? {
              ...state.dischargeSummary[action.patientId],
              loading: action.show,
            }
          : {
              loading: action.show,
            },
      },
    };
  },
  ["ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_DATA"]: (state, action) => {
    return {
      ...state,
      dischargeSummary: {
        ...state.dischargeSummary,
        [action.patientId]: state.dischargeSummary[action.patientId]
          ? {
              ...state.dischargeSummary[action.patientId],
              data: action.data,
            }
          : {
              data: action.data,
            },
      },
    };
  },
  ["ADD_PATIENT_DETAILS_DISCHARGE_SUMMARY_SESSION_DATA"]: (state, action) => {
    return {
      ...state,
      dischargeSummarySession: {
        ...state.dischargeSummarySession,
        [action.patientId]: state.dischargeSummarySession[action.patientId]
          ? {
              ...state.dischargeSummarySession[action.patientId],
              data: action.data,
            }
          : {
              data: action.data,
            },
      },
    };
  },
  ["TOGGLE_PATIENT_SESSIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      patientSessions: {
        ...state.patientSessions,
        loading: action.show,
      },
    };
  },
  ["ADD_PATIENT_SESSIONS_DATA"]: (state, action) => {
    return {
      ...state,
      patientSessions: {
        ...state.patientSessions,
        data: action.data,
      },
    };
  },
  ["ADD_PATIENT_DETAILS_VOICE_ENROLLMENT_DATA"]: (state, action) => {
    return {
      ...state,
      voiceEnrollmentData: action.data,
    };
  },
  ["RESET_PATIENT_DETAILS"]: (state, action) => {
    return initState;
  },
};

export default function patientDetails(state = initState, action) {
  let handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
