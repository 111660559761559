import React, { useState } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import bpsTemplateSchema from "./bpsTemplateSchema";
import CopyIcon from "@/assets/copy.svg";
import "./BPSTemplate.scss";

function copyTextToClipboard(text) {
  // Create a temporary textarea element
  const textarea = document.createElement("textarea");

  // Set the textarea value to the text you want to copy
  textarea.value = text;

  // Add the textarea to the document
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();

  // Copy the selected text to the clipboard
  try {
    document.execCommand("copy");
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy text", err);
  }

  // Remove the textarea from the document
  document.body.removeChild(textarea);
}

const BPSTemplate = props => {
  const [activeTab, setActiveTab] = useState(bpsTemplateSchema[0].key);
  const [textCopiedSection, setTextCopiedSection] = useState("");
  const data = props.data?.["bps-assessment"] || null;
  let copyTimer = null;
  const copyText = (text, valueKey, sectionKey, subSectionKey, subSubSectionKey) => {
    copyTextToClipboard(text);
    setTextCopiedSection(
      `${sectionKey}${subSectionKey ? `-${subSectionKey}` : ""}${
        subSubSectionKey ? `-${subSubSectionKey}` : ""
      }-${valueKey}`
    );
    if (copyTimer) {
      clearTimeout(copyTimer);
    }
    copyTimer = setTimeout(() => {
      setTextCopiedSection("");
    }, 3000);
  };
  const handleTextInputChange = (value, valueKey, sectionKey, subSectionKey, subSubSectionKey) => {
    const prevData = {
      ...props.data,
      ["bps-assessment"]: {
        ...(props.data["bps-assessment"] || {}),
        [sectionKey]: subSectionKey
          ? subSubSectionKey
            ? {
                ...(props.data["bps-assessment"][sectionKey] || {}),
                [subSectionKey]: {
                  ...(props.data["bps-assessment"][sectionKey][subSectionKey] || {}),
                  [subSubSectionKey]: {
                    ...(props.data["bps-assessment"][sectionKey][subSectionKey][subSubSectionKey] ||
                      {}),
                    [valueKey]: value,
                  },
                },
              }
            : {
                ...(props.data["bps-assessment"][sectionKey] || {}),
                [subSectionKey]: {
                  ...(props.data["bps-assessment"][sectionKey][subSectionKey] || {}),
                  [valueKey]: value,
                },
              }
          : {
              ...(props.data["bps-assessment"][sectionKey] || {}),
              [valueKey]: value,
            },
      },
    };
    props.changeSoapJsonData(prevData);
  };
  return (
    <div className="bps-notes-template">
      <div className="bps-notes-tabs-container">
        <Tabs
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          className="bps-notes-tabs-list"
        >
          {bpsTemplateSchema.map(section => {
            return (
              <Tab
                eventKey={section.key}
                title={section.label}
                key={section.key}
              >
                <div className="bps-notes-tab-content">
                  {section.value.map(subSection => {
                    return (
                      <div
                        className="bps-notes-section-content"
                        key={subSection.key}
                      >
                        {subSection.type === "subSection" && (
                          <div className="bps-notes-sub-section-content">
                            <div className="bps-notes-sub-section-label">{subSection.label}</div>
                            {subSection.value.map(valueItem => {
                              if (valueItem.type === "subSubSection") {
                                return (
                                  <React.Fragment key={valueItem.key}>
                                    <div className="bps-notes-sub-sub-section-label">
                                      {valueItem.label}
                                    </div>
                                    {valueItem.value.map(subSubSection => {
                                      return (
                                        <div
                                          className="bps-notes-sub-section-item"
                                          key={subSubSection.key}
                                        >
                                          <ValueItem
                                            valueItem={subSubSection}
                                            sectionKey={section.key}
                                            subSectionKey={subSection.key}
                                            subSubSectionKey={valueItem.key}
                                            data={data}
                                            copyText={copyText}
                                            textCopiedSection={textCopiedSection}
                                            handleTextInputChange={handleTextInputChange}
                                          />
                                        </div>
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              }
                              return (
                                <div
                                  className="bps-notes-sub-section-item"
                                  key={valueItem.key}
                                >
                                  <ValueItem
                                    valueItem={valueItem}
                                    sectionKey={section.key}
                                    subSectionKey={subSection.key}
                                    data={data}
                                    copyText={copyText}
                                    textCopiedSection={textCopiedSection}
                                    handleTextInputChange={handleTextInputChange}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {(subSection.type === "input" || subSection.type === "textarea") && (
                          <div className="bps-notes-sub-section-item">
                            <ValueItem
                              valueItem={subSection}
                              sectionKey={section.key}
                              data={data}
                              copyText={copyText}
                              textCopiedSection={textCopiedSection}
                              handleTextInputChange={handleTextInputChange}
                            />
                          </div>
                        )}
                        {subSection.type === "listOfValues" && (
                          <BpsTable
                            valueItem={subSection}
                            data={data}
                            sectionKey={section.key}
                          />
                        )}
                        {subSection.type === "checkbox" && (
                          <BpsCheckbox
                            valueItem={subSection}
                            data={data}
                            sectionKey={section.key}
                            handleTextInputChange={handleTextInputChange}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default BPSTemplate;

const ValueItem = props => {
  const {
    valueItem,
    data,
    sectionKey,
    subSectionKey,
    subSubSectionKey,
    copyText,
    handleTextInputChange,
    textCopiedSection,
  } = props;
  if (valueItem.type !== "input" && valueItem.type !== "textarea") {
    return null;
  }
  let value = subSubSectionKey
    ? data?.[sectionKey]?.[subSectionKey]?.[subSubSectionKey]?.[valueItem.key]
    : subSectionKey
    ? data?.[sectionKey]?.[subSectionKey]?.[valueItem.key]
    : data?.[sectionKey]?.[valueItem.key];
  if (typeof value === "string" || typeof value === "number") {
    value = value.toString();
  } else if (Array.isArray(value || null)) {
    value = valueItem.type === "textarea" ? value.join("\n") : value.join(", ");
  }
  return (
    <div className="bps-notes-value-item-input">
      <div className="bps-notes-value-item-label">
        {valueItem.label}
        <div
          className="copy-container"
          onClick={() =>
            copyText(value, valueItem.key, sectionKey, subSectionKey, subSubSectionKey)
          }
        >
          <img
            src={CopyIcon}
            alt=""
            className="copy-icon"
          />
          <span>
            {textCopiedSection ===
            `${sectionKey}${subSectionKey ? `-${subSectionKey}` : ""}${
              subSubSectionKey ? `-${subSubSectionKey}` : ""
            }-${valueItem.key}`
              ? "Copied"
              : "Copy"}
          </span>
        </div>
      </div>
      {valueItem.type === "input" && (
        <input
          type={valueItem.kind}
          value={value}
          onChange={e =>
            handleTextInputChange(
              e.target.value,
              valueItem.key,
              sectionKey,
              subSectionKey,
              subSubSectionKey
            )
          }
        />
      )}
      {valueItem.type === "textarea" && (
        <textarea
          value={value}
          onChange={e =>
            handleTextInputChange(
              e.target.value,
              valueItem.key,
              sectionKey,
              subSectionKey,
              subSubSectionKey
            )
          }
        />
      )}
    </div>
  );
};

const BpsTable = ({ valueItem, data, sectionKey }) => {
  let tableColumns = valueItem?.value || [];
  return (
    <div className="bps-table-container">
      {valueItem.label && <div className="bps-notes-sub-section-label">{valueItem.label}</div>}
      <table className="bps-table">
        <thead>
          <tr>
            {tableColumns.map((tableColumn, index) => {
              return <th key={index}>{tableColumn.label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.[sectionKey]?.[valueItem.key]?.map((i, index) => {
            return (
              <tr key={index}>
                {tableColumns.map((tableColumn, index2) => {
                  return <td key={index2}>{i?.[tableColumn.key] || ""}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const BpsCheckbox = ({ valueItem, data, sectionKey, handleTextInputChange }) => {
  return (
    <Form.Check
      type="checkbox"
      label={valueItem.label}
      name={`${sectionKey}-${valueItem.key}`}
      id={`${sectionKey}-${valueItem.key}`}
      checked={data?.[sectionKey]?.[valueItem.key] || false}
      onChange={() =>
        handleTextInputChange(!data?.[sectionKey]?.[valueItem.key], valueItem.key, sectionKey)
      }
      className="bps-notes-checkbox"
    />
  );
};
