let bpsTemplateSchema = [
  {
    key: "diagnosisProblems",
    type: "section",
    label: "Diagnosis/Problems",
    value: [
      {
        key: "diagnosisProblems",
        type: "listOfValues",
        label: "Diagnosis/Problems",
        value: [
          {
            key: "ICD10_DSM5_Code",
            type: "input",
            kind: "text",
            label: "ICD-10/DSM-5 Code",
          },
          {
            key: "problemName",
            type: "input",
            kind: "text",
            label: "Problem Name",
          },
          {
            key: "priority",
            type: "input",
            kind: "text",
            label: "Priority",
          },
          {
            key: "problemGroup",
            type: "input",
            kind: "text",
            label: "Problem Group",
          },
        ],
      },
    ],
  },
  {
    key: "bps",
    type: "section",
    label: "BioPsychoSocial",
    value: [
      {
        key: "psychologicalFactors",
        type: "subSection",
        label: "Psychological Factors (Mental Health and Emotional Well-being):",
        value: [
          {
            key: "presentingProblem",
            type: "textarea",
            kind: "text",
            label: "Presenting Problem:",
          },
          {
            key: "mentalHealthDiagnosis",
            type: "textarea",
            kind: "text",
            label: "Mental Health Diagnosis:",
          },
          {
            key: "previousPsychologicalTreatment",
            type: "textarea",
            kind: "text",
            label: "Previous Psychological Treatment:",
          },
          {
            key: "symptomsAndBehaviors",
            type: "textarea",
            kind: "text",
            label: "Symptoms and Behaviors:",
          },
          {
            key: "copingSkills",
            type: "textarea",
            kind: "text",
            label: "Coping Skills:",
          },
          {
            key: "sleepPatterns",
            type: "textarea",
            kind: "text",
            label: "Sleep Patterns:",
          },
          {
            key: "cognitiveFunctioning",
            type: "textarea",
            kind: "text",
            label: "Cognitive Functioning:",
          },
          {
            key: "eatingPatterns",
            type: "textarea",
            kind: "text",
            label: "Eating Patterns:",
          },
          {
            key: "exerciseRoutine",
            type: "textarea",
            kind: "text",
            label: "Exercise Routine:",
          },
          {
            key: "traumaHistory",
            type: "textarea",
            kind: "text",
            label: "Trauma History:",
          },
        ],
      },
      {
        key: "biographicalInformation",
        type: "subSection",
        label: "Biographical Information:",
        value: [
          {
            key: "currentLivingSituation",
            type: "textarea",
            kind: "text",
            label: "Current Living Situation:",
          },
          {
            key: "maritalStatus",
            type: "textarea",
            kind: "text",
            label: "Marital Status:",
          },
          {
            key: "children",
            type: "textarea",
            kind: "text",
            label: "Children:",
          },
          {
            key: "employmentStatus",
            type: "textarea",
            kind: "text",
            label: "Employment Status:",
          },
          {
            key: "educationLevel",
            type: "textarea",
            kind: "text",
            label: "Education Level:",
          },
        ],
      },
      {
        key: "biologicalFactors",
        type: "subSection",
        label: "Biological Factors (Physical Health and Medical History)",
        value: [
          {
            key: "medicalHistory",
            type: "textarea",
            kind: "text",
            label: "Medical History:",
          },
          {
            key: "medications",
            type: "textarea",
            kind: "text",
            label: "Are you taking any medication?",
          },
          {
            key: "familyMedicalHistory",
            type: "textarea",
            kind: "text",
            label: "Family Medical History:",
          },
          {
            key: "physicalDisabilities",
            type: "textarea",
            kind: "text",
            label: "Physical Disabilities:",
          },
        ],
      },
      {
        key: "socialFactors",
        type: "subSection",
        label: "Social Factors (Environment and Support System):",
        value: [
          {
            key: "familyRelationships",
            type: "textarea",
            kind: "text",
            label: "Family Relationships:",
          },
          {
            key: "socialSupportNetwork",
            type: "textarea",
            kind: "text",
            label: "Social Support Network:",
          },
          {
            key: "socialAndCulturalInfluences",
            type: "textarea",
            kind: "text",
            label: "Social and Cultural Influences:",
          },
          {
            key: "workplaceFunctioning",
            type: "textarea",
            kind: "text",
            label: "Workplace Functioning:",
          },
          {
            key: "legalIssues",
            type: "textarea",
            kind: "text",
            label: "Legal Issues:",
          },
          {
            key: "financialStatus",
            type: "textarea",
            kind: "text",
            label: "Financial Status:",
          },
        ],
      },
      {
        key: "riskAndProtectiveFactors",
        type: "subSection",
        label: "Risk and Protective Factors",
        value: [
          {
            key: "riskFactors",
            type: "subSubSection",
            label: "Risk Factors",
            value: [
              {
                key: "suicidalIdeation",
                type: "textarea",
                kind: "text",
                label: "Suicidal Ideation:",
              },
              {
                key: "selfHarm",
                type: "textarea",
                kind: "text",
                label: "Self Harm:",
              },
              {
                key: "violenceOrAggression",
                type: "textarea",
                kind: "text",
                label: "Violence or Aggression/Homicidal Ideation:",
              },
              {
                key: "poorCopingSkills",
                type: "textarea",
                kind: "text",
                label: "Poor Coping Skills:",
              },
            ],
          },
          {
            key: "protectiveFactors",
            type: "subSubSection",
            label: "Protective Factors",
            value: [
              {
                key: "strengths",
                type: "textarea",
                kind: "text",
                label: "Strengths:",
              },
              {
                key: "hobbies",
                type: "textarea",
                kind: "text",
                label: "Hobbies:",
              },
              {
                key: "motivationForChange",
                type: "textarea",
                kind: "text",
                label: "Motivation for Change:",
              },
            ],
          },
        ],
      },
      {
        key: "treatmentPlan",
        type: "subSection",
        label: "Treatment Plan and Goals:",
        value: [
          {
            key: "treatmentGoals",
            type: "textarea",
            kind: "text",
            label: "Treatment Goals:",
          },
          {
            key: "recommendedInterventions",
            type: "textarea",
            kind: "text",
            label: "Recommended Interventions:",
          },
          {
            key: "followUpPlans",
            type: "textarea",
            kind: "text",
            label: "Follow-up Plans:",
          },
        ],
      },
      {
        key: "summaryAndConclusions",
        type: "subSection",
        label: "Summary and Conclusions:",
        value: [
          {
            key: "clinicalImpressions",
            type: "textarea",
            kind: "text",
            label: "Clinical Impressions:",
          },
        ],
      },
    ],
  },
  {
    key: "substanceUseHistory",
    type: "section",
    label: "Substance Use History",
    value: [
      {
        key: "substanceUseHistory",
        type: "listOfValues",
        label: "Substance Use History",
        value: [
          {
            key: "substanceUsed",
            type: "input",
            kind: "text",
            label: "Substance Used",
          },
          {
            key: "daysUsedInTheLast30Days",
            type: "input",
            kind: "text",
            label: "Days Used in the Last 30 Days",
          },
          {
            key: "routeOfUse",
            type: "input",
            kind: "text",
            label: "Route of Use",
          },
          {
            key: "usualAmountUsedInA24HourDay",
            type: "input",
            kind: "text",
            label: "Usual Amount Used in a 24 Hour Day",
          },
          {
            key: "dateOfLastUse",
            type: "input",
            kind: "text",
            label: "Date of Last Use",
          },
          {
            key: "ageFirstUsed",
            type: "input",
            kind: "text",
            label: "Age First Used",
          },
          {
            key: "yearsOfUse",
            type: "input",
            kind: "text",
            label: "Years of Use",
          },
          {
            key: "methodOfAcquiringSubstance",
            type: "input",
            kind: "text",
            label: "Method of Acquiring Substance",
          },
          {
            key: "patternOfUse",
            type: "input",
            kind: "text",
            label: "Pattern of Use",
          },
        ],
      },
    ],
  },
  {
    key: "previousTreatments",
    type: "section",
    label: "Previous Treatments",
    value: [
      {
        key: "previousTreatments",
        type: "listOfValues",
        label: "Previous Treatments",
        value: [
          {
            key: "tXCenterName",
            type: "input",
            kind: "text",
            label: "TX Center Name",
          },
          {
            key: "duration",
            type: "input",
            kind: "text",
            label: "Duration",
          },
          {
            key: "levelOfCare",
            type: "input",
            kind: "text",
            label: "Level of Care",
          },
          {
            key: "responseToPreviousTreatment",
            type: "textarea",
            kind: "text",
            label:
              "What was the response to previous treatment? How long did you stay sober after discharge?",
          },
        ],
      },
    ],
  },
  {
    key: "physicalPain",
    type: "section",
    label: "Physical Pain",
    value: [
      {
        key: "painScale",
        type: "input",
        kind: "text",
        label: "Pain Scale",
      },
      {
        key: "planForPain8OrHigher",
        type: "textarea",
        kind: "text",
        label: "Plan for Pain 8 or higher",
      },
    ],
  },
  {
    key: "nutritionalSelfAssessment",
    type: "section",
    label: "Nutritional Self Assessment",
    value: [
      {
        key: "lost10lbsInPastMonth",
        type: "checkbox",
        label: "Have you intentionally lost>10lbs in past month?",
      },
      {
        key: "considersUnderweightOrMalnourished",
        type: "checkbox",
        label: "Do you consider yourself to be underweight or malnourished?",
      },
      {
        key: "difficultySwallowing",
        type: "checkbox",
        label: "Do you have difficulty swallowing?",
      },
      {
        key: "nutritionalOrDietaryConcerns",
        type: "checkbox",
        label: "Do you have nutritional or dietary concerns?",
      },
    ],
  },
  {
    key: "SNAGSNAP",
    type: "section",
    label: "SNAG/SNAP",
    value: [
      {
        key: "strengths",
        type: "textarea",
        kind: "text",
        label: "Strengths",
      },
      {
        key: "needs",
        type: "textarea",
        kind: "text",
        label: "Needs",
      },
      {
        key: "abilities",
        type: "textarea",
        kind: "text",
        label: "Abilities",
      },
      {
        key: "goals",
        type: "textarea",
        kind: "text",
        label: "Goals",
      },
    ],
  },
];

export default bpsTemplateSchema;
