import React, { useEffect, useMemo, useState } from "react";
import { Avatar } from "@/components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "./MemberDetails.scss";

import { groupDynamics } from "@/services/dummyData";

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

function MemberDetails(props) {
  const getSpeakerName = speakerLabel => {
    let speakerName = "";
    let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
      ? props.speakerRecognition?.data?.[props.sessionId][
          Object.keys(props.speakerRecognition.data[props.sessionId])[0]
        ]
      : null;
    if (!speakerRecognitionData) {
      return speakerName;
    }
    let speakerData = speakerRecognitionData[speakerLabel] || null;
    if (!speakerData) {
      return speakerName;
    }
    // if (speakerData.predictedLabel?.toLowerCase() === "provider") {
    //   if (props.sessionData?.practitionerFirstName) {
    //     speakerName = `${props.sessionData.practitionerFirstName || ""}${
    //       props.sessionData.practitionerLastName ? " " : ""
    //     }${props.sessionData.practitionerLastName || ""}`;
    //     return speakerName;
    //   }
    // }
    let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
    let speakerPatient = sessionPatientMemberDtos.find(
      i => i.patientId === speakerData.speakerUUID
    );
    if (speakerPatient) {
      speakerName = `${speakerPatient.patientFirstName || ""}${
        speakerPatient.patientLastName ? " " : ""
      }${speakerPatient.patientLastName || ""}`;
    }
    return speakerName;
  };
  const data = useMemo(() => {
    let speakerVoicePrint = props.speakerVoicePrint?.data || {};
    let data = [];
    if (speakerVoicePrint && Object.keys(speakerVoicePrint)?.length > 0) {
      Object.keys(speakerVoicePrint).forEach(providerSessionId => {
        let providerSessionData = speakerVoicePrint[providerSessionId];
        Object.keys(providerSessionData).forEach(speakerLabel => {
          let speakerName = getSpeakerName(
            `Speaker ${providerSessionData[speakerLabel].speakerId}`
          );
          if (speakerName) {
            data.push({
              name: speakerName,
              talktime: Math.round(providerSessionData[speakerLabel].audioDuration / 60),
              memberPhoto: providerSessionData[speakerLabel].memberPhoto,
            });
          }
        });
      });
    }
    return data;
  }, [
    props.sessionData,
    props.speakerVoicePrint,
    props.speakerRecognition?.data?.[props.sessionId],
  ]);
  const totalParticipants = useMemo(() => {
    let totalParticipants = 0;
    if (data && data?.length > 0) {
      totalParticipants = data?.length;
    }
    return totalParticipants;
  }, [data]);
  const totalTalktime = useMemo(() => {
    let totalTalktime = 0;
    let speakerVoicePrint = props.speakerVoicePrint?.data || {};
    if (speakerVoicePrint && Object.keys(speakerVoicePrint)?.length > 0) {
      Object.keys(speakerVoicePrint).forEach(providerSessionId => {
        let providerSessionData = speakerVoicePrint[providerSessionId];
        Object.keys(providerSessionData).forEach(speakerLabel => {
          let speakerName = getSpeakerName(
            `Speaker ${providerSessionData[speakerLabel].speakerId}`
          );
          if (speakerName) {
            totalTalktime += providerSessionData[speakerLabel].audioDuration / 60;
          }
        });
      });
    }
    return totalTalktime;
  }, [
    props.sessionData,
    props.speakerVoicePrint,
    props.speakerRecognition?.data?.[props.sessionId],
  ]);
  const talktimeThreshold = useMemo(() => {
    let talktimeThreshold = 0;
    if (totalTalktime && totalParticipants) {
      talktimeThreshold = totalTalktime / totalParticipants;
    }
    return talktimeThreshold;
  }, [totalParticipants, totalTalktime]);
  return (
    <div className="member-details-widget">
      <div className="member-list-container">
        <div className="member-list-label">
          Talk Time <span className="balanced-label">Balanced</span>
        </div>
        <div className="member-list">
          {data.map((member, index) => {
            let talktimeValue = member.talktime;
            return (
              <div
                className="member-avatar-container"
                key={index}
              >
                <div className="member-avatar">
                  <Avatar
                    name={member.name}
                    imageSrc={member.memberPhoto}
                  />
                </div>
                <div className="member-talktime-container">
                  <div className="member-talktime">
                    <div className="member-name">{member.name}</div>
                    <div className="member-talktime">{member.talktime} min</div>
                  </div>
                  <div className="talktime-bar-container">
                    <div
                      className={`talktime-bar ${
                        talktimeValue >= talktimeThreshold ? "balanced" : ""
                      }`}
                      style={{ width: `${(talktimeValue * 100) / totalTalktime}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MemberDetails;
