import React, { useEffect, useMemo, useRef, useState } from "react";
import "./SpeakerRecognitionLoader.scss";
import { Loader } from "@/components";

const SpeakerRecognitionLoader = ({
  sessionId,
  transcriptData,
  speakerRecognition,
  sessionCategory,
  sessionData,
  ...props
}) => {
  const { data: speakerRecognitionData, loading } = speakerRecognition;
  const [providerSessionsSpeakers, setProviderSessionsSpeakers] = useState([]);
  const totalSpeakers = useRef(0);
  useEffect(() => {
    if (transcriptData?.length > 0) {
      totalSpeakers.current = 0;
      transcriptData.forEach(providerSession => {
        let newProviderSessionsSpeakers = [...providerSessionsSpeakers];
        let providerSessionTranscriptData = providerSession.transcriptData;
        let uniqueSpeakers = [
          ...new Set(providerSessionTranscriptData.map(item => item.providerParticipantId)),
        ];
        uniqueSpeakers = uniqueSpeakers.filter(i => i?.includes("Speaker "));
        uniqueSpeakers = uniqueSpeakers.map(i => i?.replace("Speaker ", ""));
        totalSpeakers.current = totalSpeakers.current + uniqueSpeakers.length;
        newProviderSessionsSpeakers.push({
          providerSessionId: providerSession.providerSessionId,
          speakers: uniqueSpeakers,
        });
        setProviderSessionsSpeakers(newProviderSessionsSpeakers);
      });
    }
  }, [transcriptData]);

  useEffect(() => {
    if (sessionId && providerSessionsSpeakers?.length > 0 && sessionData) {
      props.getSessionSpeakerRecognitionStatus({
        sessionId,
        providerSessionsSpeakers,
        sessionData,
      });
    }
  }, [sessionId, providerSessionsSpeakers, sessionData]);
  const recognisedSpeakersCount = useMemo(() => {
    let totalRecognised = 0;
    if (speakerRecognitionData?.[sessionId]) {
      Object.keys(speakerRecognitionData[sessionId]).forEach(providerSessionId => {
        totalRecognised =
          totalRecognised +
          Object.keys(speakerRecognitionData[sessionId][providerSessionId]).length;
      });
    }
    return totalRecognised;
  }, [speakerRecognitionData]);

  const [startMessage, setStartMessage] = useState(true); // For showing the initial message
  const [completionMessage, setCompletionMessage] = useState(""); // To store the final message
  const [speakerRecognitionStarted, setSpeakerRecognitionStarted] = useState(false);
  const [recognitionError, setRecognitionError] = useState(false);

  // Determine progress message
  const progressMessage =
    recognisedSpeakersCount === totalSpeakers.current
      ? "All speakers have been recognised!"
      : `Speaker Recognition in progress: ${recognisedSpeakersCount} of ${totalSpeakers.current} recognised.`;

  // Manage message logic on loading state changes
  useEffect(() => {
    if (loading) {
      setStartMessage(true); // Show start message initially
      setCompletionMessage(""); // Clear any previous completion message
      setSpeakerRecognitionStarted(true);
      setRecognitionError(false);
      const timer = setTimeout(() => {
        setStartMessage(false); // Switch to progress message after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Clean up timeout
    } else if (!loading && totalSpeakers.current > 0) {
      // Set the final message based on error or success
      if (speakerRecognitionStarted) {
        setCompletionMessage(
          recognisedSpeakersCount === totalSpeakers.current
            ? "All speakers have been recognised!"
            : "Some speakers could not be recognised."
        );
        setRecognitionError(recognisedSpeakersCount !== totalSpeakers.current);
      } else {
        setCompletionMessage("");
        setRecognitionError(false);
      }
    }
  }, [loading, totalSpeakers.current]);

  if (!loading && !completionMessage) {
    return null; // Do not show anything if no enrollment occurred
  }

  return (
    <div
      className={`speaker-recognition-loader-container ${
        startMessage
          ? "recognition-started"
          : !loading && completionMessage
          ? recognitionError
            ? "recognition-error"
            : "recognition-success"
          : "recognition-in-progress"
      }`}
    >
      <Loader loading={loading} />
      <div className="recognition-loader-msg">
        {loading
          ? startMessage
            ? "Speaker Recognition started..."
            : progressMessage
          : completionMessage}
      </div>
    </div>
  );
};

export default SpeakerRecognitionLoader;
