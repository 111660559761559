import React, { useState } from "react";
import { Avatar, PrimaryButton } from "@/components";
import PredictionCards from "../PredictionCards";
import DischargeSummary from "../DischargeSummary";
import VoicePrintIcon from "@/assets/voice-print.svg";
import VoicePrintCapturedIcon from "@/assets/voice-print-captured.svg";
import "./Profile.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function Profile(props) {
  const { data } = props;
  const [dischargeSummaryOpen, setDischargeSummaryOpen] = useState(false);
  const enableVoiceRecognitionFeature =
    APP_ENV === "DEV" ||
    [
      "de4c00e0-101a-4483-a4b4-b6ca0041946b",
      "da9a7c20-025e-4b96-93a1-fbd82be5d211",
      "e8de0294-6616-4b2b-b114-c29a6d0fb302",
      "dad3db72-1f0e-4de5-b82a-27bc03c586d3",
    ].includes(props.selectedUserRole?.organizationId); //Enabled on all dev orgs & Soulside Inc (anurag+soulside_owner@getsoulside.com), Test Org 1, Test Org 2 (anurag+testgp1@getsoulside.com), Soulside Demo org on prod
  return (
    <div className="profile-widget">
      <div className="profile-header">
        <Avatar name={`${data?.firstName} ${data?.lastName}`} />
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
            <div className="member-name widget-title">
              {data?.firstName} {data?.lastName}
            </div>
            {enableVoiceRecognitionFeature && (
              <div className="voice-enrollment-status">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {props.voiceEnrollmentData?.speakerProfileEnrolled
                        ? "Voice print enrolled"
                        : "Voice print not enrolled"}
                    </Tooltip>
                  }
                >
                  {props.voiceEnrollmentData?.speakerProfileEnrolled ? (
                    <a
                      target="_blank"
                      href={props.voiceEnrollmentData?.speakerProfileEnrollmentFileUrl}
                    >
                      <img
                        src={
                          props.voiceEnrollmentData?.speakerProfileEnrolled
                            ? VoicePrintCapturedIcon
                            : VoicePrintIcon
                        }
                        className="voice-enrollment-icon"
                        alt=""
                      />
                    </a>
                  ) : (
                    <img
                      src={
                        props.voiceEnrollmentData?.speakerProfileEnrolled
                          ? VoicePrintCapturedIcon
                          : VoicePrintIcon
                      }
                      className="voice-enrollment-icon"
                      alt=""
                    />
                  )}
                </OverlayTrigger>
              </div>
            )}
          </div>
          <div className="member-id">ID: {data?.id}</div>
        </div>
      </div>
      <div className="member-details">
        {!!data?.age && (
          <div className="member-detail-item">
            <div className="detail-label">Age:</div>
            <div className="detail-value">{data?.age}</div>
          </div>
        )}
        {data?.location && (
          <div className="member-detail-item">
            <div className="detail-label">Location:</div>
            <div className="detail-value">{data?.location}</div>
          </div>
        )}
        {/* <div className="member-detail-item">
          <div className="detail-label">Location:</div>
          <div className="detail-value">
            {data?.patient_city}, {data?.patient_state}
          </div>
        </div> */}
        <div className="member-detail-item">
          <div className="detail-label">Email:</div>
          <div className="detail-value">{data?.email || props.getDummyEmail(data?.firstName)}</div>
        </div>
        <div className="member-detail-item">
          <div className="detail-label">Phone Number:</div>
          <div className="detail-value">{data?.phoneNumber || props.getDummyPhoneNumber()}</div>
        </div>
      </div>
      {!!props.behaviouralHealthPredictionsData && (
        <PredictionCards data={props.behaviouralHealthPredictionsData} />
      )}
      {/* {APP_ENV === "DEV" && ( */}
      <>
        <div className="discharge-message-container">
          <div className="discharge-message">Ready to Discharge?</div>
          <PrimaryButton onClick={() => setDischargeSummaryOpen(true)}>
            Get Discharge Summary
          </PrimaryButton>
        </div>
        <DischargeSummary
          modalOpen={dischargeSummaryOpen}
          toggleModal={setDischargeSummaryOpen}
          dischargeSummary={props.dischargeSummary}
          dischargeSummarySession={props.dischargeSummarySession}
          generateDischargeSummary={props.generateDischargeSummary}
          sessions={props.sessions}
          patientData={props.data}
          selectedUserRole={props.selectedUserRole}
          getDischargeSummary={props.getDischargeSummary}
          saveDischargeSummary={props.saveDischargeSummary}
          preferredTimezone={props.preferredTimezone}
        />
      </>
      {/* )} */}
    </div>
  );
}

export default Profile;
