import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form, Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import "./VoicePrintMapping.scss";
import { PrimaryButton, SecondaryButton, MemberAvatar } from "@/components";
import session from "@/Routes/Session/sessionModule";

function VoicePrintMapping(props) {
  const [speakerMappedData, setSpeakerMappedData] = useState({});
  const speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId];
  const speakerMappingTranscriptData = useMemo(() => {
    let data =
      props.providerSessionsTranscriptData?.map(providerSessionTranscript => {
        let speakerTranscriptData = {};
        providerSessionTranscript.transcriptData?.forEach(transcript => {
          const providerParticipantId = transcript.providerParticipantId;
          if (speakerTranscriptData[providerParticipantId]) {
            speakerTranscriptData[providerParticipantId].push(transcript);
          } else {
            speakerTranscriptData[providerParticipantId] = [transcript];
          }
        });
        return {
          providerSessionId: providerSessionTranscript.providerSessionId,
          providerSession: providerSessionTranscript.providerSession,
          speakerTranscriptData,
        };
      }) || [];
    return data;
  }, [props.providerSessionsTranscriptData]);
  useEffect(() => {
    if (props.providerSessionsTranscriptData?.length > 0 && props.sessionData) {
      props.getSessionSpeakersVoicePrint({
        sessionId: props.sessionId,
        sessionCategory: props.sessionCategory,
        providerSessionsTranscriptData: props.providerSessionsTranscriptData,
        sessionData: props.sessionData,
      });
    }
  }, [props.providerSessionsTranscriptData, props.sessionData]);
  useEffect(() => {
    let data = {};
    if (props.sessionCategory === "individual") {
      if (props.providerSessionsTranscriptData?.length > 0) {
        props.providerSessionsTranscriptData.forEach(providerSessionTranscript => {
          if (providerSessionTranscript?.transcriptData?.length > 0) {
            let providerSessionId = providerSessionTranscript.providerSessionId;
            providerSessionTranscript.transcriptData.forEach(transcript => {
              data = {
                ...data,
                [providerSessionId]: data[providerSessionId]
                  ? {
                      ...data[providerSessionId],
                      [transcript.providerParticipantId]: transcript.memberName,
                    }
                  : { [transcript.providerParticipantId]: transcript.memberName },
              };
            });
          }
        });
      }
    } else {
      if (props.providerSessionsTranscriptData?.length > 0) {
        props.providerSessionsTranscriptData.forEach(providerSessionTranscript => {
          if (providerSessionTranscript?.transcriptData?.length > 0) {
            let providerSessionId = providerSessionTranscript.providerSessionId;
            providerSessionTranscript.transcriptData.forEach(transcript => {
              let memberId = transcript.memberId;
              let memberName = transcript.memberName;
              if (speakerRecognitionData?.[providerSessionId]?.[transcript.providerParticipantId]) {
                const { predictedLabel = "", speakerUUID = "" } =
                  speakerRecognitionData?.[providerSessionId]?.[memberId] || {};
                if (predictedLabel && speakerUUID) {
                  if (predictedLabel.toLowerCase() === "provider") {
                    memberName = "Provider";
                    memberId = "Provider";
                  } else {
                    memberId = speakerUUID;
                    let sessionPatientMemberDtos =
                      props.sessionData?.sessionPatientMemberDtos || [];
                    let patientObj = sessionPatientMemberDtos.find(
                      i => i.patientId === speakerUUID
                    );
                    if (patientObj) {
                      memberName = `${patientObj.patientFirstName || ""} ${
                        patientObj.patientLastName || ""
                      }`;
                    }
                  }
                }
              }
              data = {
                ...data,
                [providerSessionId]: data[providerSessionId]
                  ? {
                      ...data[providerSessionId],
                      [transcript.providerParticipantId]: {
                        id: memberId,
                        name: memberName,
                      },
                    }
                  : {
                      [transcript.providerParticipantId]: {
                        id: memberId,
                        name: memberName,
                      },
                    },
              };
            });
          }
        });
      }
    }
    setSpeakerMappedData(data);
  }, [props.providerSessionsTranscriptData, speakerRecognitionData]);
  const mapIndividualSessionSpeaker = (
    providerSessionId,
    providerParticipantId,
    participantName
  ) => {
    setSpeakerMappedData(prevData => {
      let newData = { ...prevData };
      newData = {
        ...newData,
        [providerSessionId]: newData[providerSessionId]
          ? {
              ...newData[providerSessionId],
              [providerParticipantId]: participantName,
            }
          : { [providerParticipantId]: participantName },
      };
      return newData;
    });
  };
  const mapGroupSessionSpeaker = (providerSessionId, providerParticipantId, participant) => {
    setSpeakerMappedData(prevData => {
      let newData = { ...prevData };
      newData = {
        ...newData,
        [providerSessionId]: newData[providerSessionId]
          ? {
              ...newData[providerSessionId],
              [providerParticipantId]: participant,
            }
          : { [providerParticipantId]: participant },
      };
      return newData;
    });
  };
  const getTime = time => {
    if (!time) return "";
    return `${moment(time)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .format("MMMM DD")}, ${moment(time)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
  };
  const saveMapping = () => {
    let providerSessionsTranscriptData = props.providerSessionsTranscriptData.map(
      providerSessionTranscript => {
        let providerSessionId = providerSessionTranscript.providerSessionId;
        let providerSessionTranscriptData = providerSessionTranscript.transcriptData.map(
          transcript => {
            let providerParticipantId = transcript.providerParticipantId;
            let mappedData =
              speakerMappedData?.[providerSessionId]?.[providerParticipantId] || null;
            if (mappedData) {
              const memberName =
                props.sessionCategory === "individual"
                  ? mappedData
                  : mappedData?.name || transcript.memberName;
              const memberId =
                props.sessionCategory === "individual"
                  ? mappedData
                  : mappedData?.id || transcript.memberId;
              return {
                ...transcript,
                memberName,
                memberId,
              };
            }
            return transcript;
          }
        );
        return {
          ...providerSessionTranscript,
          transcriptData: providerSessionTranscriptData,
        };
      }
    );
    props.saveSpeakerMapping({
      sessionData: props.sessionData,
      providerSessionsTranscriptData,
      sessionId: props.sessionId,
      sessionCategory: props.sessionCategory,
    });
    props.toggleModal(false);
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="voice-print-mapping-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Map Speakers</Modal.Title>
      </Modal.Header>
      <Modal.Body className="voice-print-mapping-modal-body">
        <div className="speaker-mapping-list">
          {speakerMappingTranscriptData?.map((providerSessionTranscript, index) => {
            const providerSessionId = providerSessionTranscript.providerSessionId;
            const speakerTranscriptData = providerSessionTranscript.speakerTranscriptData;
            return (
              <div
                className="speaker-mapping-provider-session-list"
                key={providerSessionId}
              >
                {speakerMappingTranscriptData?.length > 1 && (
                  <div className="speaker-mapping-provider-session-header">
                    <div className="speaker-mapping-provider-session-label">
                      Microsession {index + 1}
                    </div>
                    <div className="speaker-mapping-provider-session-time">
                      {getTime(providerSessionTranscript.providerSession?.startedAt)}
                    </div>
                  </div>
                )}
                {Object.keys(speakerTranscriptData)?.map((providerParticipantId, index) => {
                  let speakerId =
                    speakerTranscriptData[
                      providerParticipantId
                    ]?.[0]?.providerParticipantId?.replace("Speaker ", "") || "";
                  let speakerMapped =
                    speakerMappedData?.[providerSessionId]?.[providerParticipantId] || null;
                  let speakerMappedName =
                    props.sessionCategory === "individual"
                      ? speakerMapped || ""
                      : speakerMapped?.name || "";
                  let dropdownTitle = "Select Patient";
                  let groupSessionPatientSelected = false;
                  let practitionerFullName = `${props.sessionData?.practitionerFirstName || ""}${
                    props.sessionData?.practitionerLastName ? " " : ""
                  }${props.sessionData?.practitionerLastName || ""}`;
                  if (props.sessionCategory === "group") {
                    if (
                      !["Provider", "Nurse Practitioner", "Unknown"].includes(speakerMappedName)
                    ) {
                      groupSessionPatientSelected = true;
                    }
                    dropdownTitle =
                      !groupSessionPatientSelected || !speakerMappedName
                        ? "Select Patient"
                        : speakerMappedName;
                  }
                  const voicePrintUrl =
                    props.speakerVoicePrint?.data?.[providerSessionId]?.[speakerId]
                      ?.voicePrintFileUrl;
                  const voicePrintAudioDuration =
                    props.speakerVoicePrint?.data?.[providerSessionId]?.[speakerId]
                      ?.audioDuration || 0;
                  const isSpeakerMapped = !speakerMappedName.includes("Speaker");
                  return (
                    <div
                      className="speaker-mapping-item"
                      key={providerParticipantId}
                    >
                      <div className="speaker-mapping-item-patient-header">
                        <div className="speaker-mapping-item-patient-label">
                          Speaker {index + 1}
                        </div>
                        {voicePrintUrl &&
                          props.speakerVoicePrint?.data?.[providerSessionId]?.[speakerId]
                            ?.voicePrintEnrolled && (
                            <div className="speaker-mapping-item-voiceprint">
                              <audio controls>
                                <source
                                  src={voicePrintUrl}
                                  type="audio/wav"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          )}
                      </div>
                      {!isSpeakerMapped &&
                        voicePrintAudioDuration < 30 &&
                        props.sessionCategory === "group" && (
                          <div className="speaker-mapping-error">
                            We couldn't map this speaker as the audio recorded for this speaker is
                            less than 30 seconds. Please map the speaker manually.
                          </div>
                        )}
                      <div className="speaker-mapping-item-patient-container">
                        {props.sessionCategory === "group" && (
                          <div>
                            <div className="speaker-patient-mapping-container">
                              <Form.Label className="speaker-patient-label">
                                Select Patient:
                              </Form.Label>
                              <Dropdown className="speaker-patient-dropdown">
                                <Dropdown.Toggle disabled={!groupSessionPatientSelected}>
                                  {dropdownTitle}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {props.sessionData?.sessionPatientMemberDtos?.length > 0 &&
                                    props.sessionData.sessionPatientMemberDtos.map(patient => {
                                      let patientName = `${patient?.patientFirstName || ""}${
                                        patient?.patientLastName ? " " : ""
                                      }${patient?.patientLastName || ""}`;
                                      return (
                                        <Dropdown.Item
                                          as={"button"}
                                          onClick={() =>
                                            mapGroupSessionSpeaker(
                                              providerSessionId,
                                              providerParticipantId,
                                              {
                                                id: patient.patientId,
                                                name: patientName,
                                              }
                                            )
                                          }
                                          key={patient.patientId}
                                        >
                                          <MemberAvatar
                                            name={patientName}
                                            size={"small"}
                                            showFullName={true}
                                          />
                                        </Dropdown.Item>
                                      );
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="speaker-checkbox-list">
                              <div
                                className="checkbox-container"
                                onClick={() => {
                                  if (groupSessionPatientSelected) {
                                    console.log("Patient Selected");
                                  } else {
                                    mapGroupSessionSpeaker(
                                      providerSessionId,
                                      providerParticipantId,
                                      null
                                    );
                                  }
                                }}
                              >
                                <div
                                  className={`custom-checkbox ${
                                    groupSessionPatientSelected ? "checked" : ""
                                  }`}
                                >
                                  <span className="checkbox-icon"></span>
                                </div>
                                <div className="checkbox-label">Patient</div>
                              </div>
                              <div
                                className="checkbox-container"
                                onClick={() =>
                                  mapGroupSessionSpeaker(providerSessionId, providerParticipantId, {
                                    name: "Provider",
                                    id: "Provider",
                                  })
                                }
                              >
                                <div
                                  className={`custom-checkbox ${
                                    speakerMapped?.id === "Provider" ||
                                    speakerMapped?.name === "Provider" ||
                                    practitionerFullName
                                      ?.trim?.()
                                      ?.toLowerCase?.()
                                      ?.includes(speakerMapped?.name?.trim?.().toLowerCase?.())
                                      ? "checked"
                                      : ""
                                  }`}
                                >
                                  <span className="checkbox-icon"></span>
                                </div>
                                <div className="checkbox-label">Provider</div>
                              </div>
                              <div
                                className="checkbox-container"
                                onClick={() =>
                                  mapGroupSessionSpeaker(providerSessionId, providerParticipantId, {
                                    name: "Nurse Practitioner",
                                    id: "Nurse Practitioner",
                                  })
                                }
                              >
                                <div
                                  className={`custom-checkbox ${
                                    speakerMappedData?.[providerSessionId]?.[providerParticipantId]
                                      ?.id === "Nurse Practitioner"
                                      ? "checked"
                                      : ""
                                  }`}
                                >
                                  <span className="checkbox-icon"></span>
                                </div>
                                <div className="checkbox-label">Nurse Practitioner</div>
                              </div>
                              <div
                                className="checkbox-container"
                                onClick={() =>
                                  mapGroupSessionSpeaker(providerSessionId, providerParticipantId, {
                                    name: "Unknown",
                                    id: "Unknown",
                                  })
                                }
                              >
                                <div
                                  className={`custom-checkbox ${
                                    speakerMappedData?.[providerSessionId]?.[providerParticipantId]
                                      ?.id === "Unknown"
                                      ? "checked"
                                      : ""
                                  }`}
                                >
                                  <span className="checkbox-icon"></span>
                                </div>
                                <div className="checkbox-label">Unknown</div>
                              </div>
                            </div>
                          </div>
                        )}
                        {props.sessionCategory === "individual" && (
                          <div className="speaker-checkbox-list">
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapIndividualSessionSpeaker(
                                  providerSessionId,
                                  providerParticipantId,
                                  "Patient"
                                )
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[
                                    providerParticipantId
                                  ] === "Patient"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Patient</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapIndividualSessionSpeaker(
                                  providerSessionId,
                                  providerParticipantId,
                                  "Provider"
                                )
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[
                                    providerParticipantId
                                  ] === "Provider"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Provider</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapIndividualSessionSpeaker(
                                  providerSessionId,
                                  providerParticipantId,
                                  "Patient Family"
                                )
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[
                                    providerParticipantId
                                  ] === "Patient Family"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Patient Family</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapIndividualSessionSpeaker(
                                  providerSessionId,
                                  providerParticipantId,
                                  "Nurse Practitioner"
                                )
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[
                                    providerParticipantId
                                  ] === "Nurse Practitioner"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Nurse Practitioner</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapIndividualSessionSpeaker(
                                  providerSessionId,
                                  providerParticipantId,
                                  "Unknown"
                                )
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[
                                    providerParticipantId
                                  ] === "Unknown"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Unknown</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={() => props.toggleModal(false)}>Cancel</SecondaryButton>
        <PrimaryButton onClick={saveMapping}>Submit</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default VoicePrintMapping;
